<template>
  <div style="height: 100%; width: 100%;"
    class="is-flex is-flex-direction-row">

    <div class="quote-booking-side-panel is-flex is-flex-direction-column is-align-items-center"
      v-if="!sideSubMenu">

      <div class="label-text has-text-primary mt-4">
        <span class="text">Booking</span>
      </div>

      <div class="is-flex is-flex-direction-column mt-4 "
        style="width: 80%;">
        <span class="booking-field-label">Quote Date</span>
        <div class="field"
          style="width: 100%;">
          <v-date-picker v-model="value.quoteDate"
            :class="{'disabled': value.readOnly}"
            :masks="formats"
            popover.visibility="focus"
            :attributes="attrs"
            :locale="$userInfo.locale"
            :model-config="{ timeAdjust: '00:00:00' }"
            :timezone="$filters.getIanaTimezone()">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="field">
                <div class="control has-icons-left has-icons-right">
                  <input type="text"
                    class="input"
                    :class="[`input-${$user.info.theme}`]"
                    placeholder="Date From..."
                    :value="inputValue"
                    v-on="inputEvents">
                  <span class="icon is-small is-left">
                    <i class="mdi mdi-calendar mdi-18px" />
                  </span>

                  <span v-if="!!value.quoteDate && !value.readOnly"
                    class="icon is-right is-clickable"
                    @click="value.quoteDate = null">
                    <i class="mdi mdi-close has-text-white" />
                  </span>

                </div>
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="is-flex is-flex-direction-column mt-4 "
        style="width: 80%;">
        <span class="booking-field-label">Estimated Start Date</span>
        <div class="field"
          style="width: 100%;">
          <v-date-picker v-model="value.jobStart"
            :class="{'disabled':!value.quoteDate || value.readOnly}"
            mode="dateTime"
            :masks="formats"
            :max-date="value.jobEnd"
            popover.visibility="focus"
            :attributes="attrs"
            :locale="$userInfo.locale"
            :timezone="$filters.getIanaTimezone()">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="field">
                <div class="control has-icons-left has-icons-right">
                  <input type="text"
                    class="input"
                    :class="[`input-${$user.info.theme}`]"
                    placeholder="Date From..."
                    :value="inputValue"
                    v-on="inputEvents">
                  <span class="icon is-small is-left">
                    <i class="mdi mdi-calendar mdi-18px" />
                  </span>

                  <span v-if="!!value.jobStart && !value.readOnly"
                    class="icon is-right is-clickable"
                    @click="value.jobStart = null">
                    <i class="mdi mdi-close has-text-white" />
                  </span>

                </div>
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="is-flex is-flex-direction-column mt-4 "
        style="width: 80%;">
        <span class="booking-field-label">Estimated End Dates</span>
        <div class="field"
          style="width: 100%;">
          <v-date-picker v-model="value.jobEnd"
            :class="{'disabled': !value.jobStart || value.readOnly}"
            mode="dateTime"
            :min-date="value.jobStart"
            :masks="formats"
            popover.visibility="focus"
            :attributes="attrs"
            :locale="$userInfo.locale"
            :timezone="$filters.getIanaTimezone()">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="field">
                <div class="control has-icons-left has-icons-right">
                  <input type="text"
                    class="input"
                    :class="[`input-${$user.info.theme}`]"
                    placeholder="Date From..."
                    :value="inputValue"
                    v-on="inputEvents">
                  <span class="icon is-small is-left">
                    <i class="mdi mdi-calendar mdi-18px" />
                  </span>

                  <span v-if="!!value.jobEnd && !value.readOnly"
                    class="icon is-right is-clickable"
                    @click="value.jobEnd = null">
                    <i class="mdi mdi-close has-text-white" />
                  </span>

                </div>
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="label-text has-text-primary mt-4">
        <span class="text">Summary</span>
      </div>

      <div class="value-text has-text-grey-light">
        <span class="text">Remove and Refit</span>
        <span class="text">{{ $filters.formatCurrency(value.rrTotal, $userInfo.locale) }}</span>
      </div>

      <div class="value-text has-text-grey-light">
        <span class="text">Repair</span>
        <span class="text">{{ $filters.formatCurrency(value.repairTotal, $userInfo.locale) }}</span>
      </div>

      <div class="value-text has-text-grey-light">
        <span class="text">Paint</span>
        <span class="text">{{ $filters.formatCurrency(value.paintTotal, $userInfo.locale) }}</span>
      </div>

      <div class="value-text has-text-grey-light">
        <span class="text">Mechanical</span>
        <span class="text">{{ $filters.formatCurrency(value.mechTotal, $userInfo.locale) }}</span>
      </div>

      <div class="value-text has-text-grey-light">
        <span class="text">Parts</span>
        <span class="text">{{ $filters.formatCurrency(value.partTotal, $userInfo.locale) }}</span>
      </div>

      <div class="value-text has-text-grey-light">
        <span class="text">Sublets</span>
        <span class="text">{{ $filters.formatCurrency(value.sublTotal, $userInfo.locale) }}</span>
      </div>

      <div class="value-text has-text-grey-light">
        <span class="text">Miscellaneous</span>
        <span class="text">{{ $filters.formatCurrency(value.miscTotal, $userInfo.locale) }}</span>
      </div>

      <div class="value-text has-text-grey-light has-text-weight-bold">
        <span class="text">Sub Total</span>
        <span class="text">{{ $filters.formatCurrency(value.totalExGst, $userInfo.locale) }}</span>
      </div>

      <div class="value-text has-text-grey-light has-text-weight-bold">
        <span class="text">GST</span>
        <span class="text">{{ $filters.formatCurrency(value.totalIncGst - value.totalExGst, $userInfo.locale) }}</span>
      </div>

      <div class="value-text has-text-grey-light  has-text-weight-bold">
        <span class="text">Total</span>
        <span class="text">{{ $filters.formatCurrency(value.totalIncGst, $userInfo.locale) }}</span>
      </div>

    </div>

    <div :style="{width: sideSubMenu ? '100%' : '80%'}">
      <capacity-component :theme="$user.info.theme"
        :update-data="updateData"
        :side-sub-menu="sideSubMenu"
        @setUpdateData="updateData = $event"
        @handleQuoteRedirect="handleQuoteRedirect" />
    </div>

  </div>
</template>

<script>
import CapacityComponent from '../../components/Calendar/CapacityCalendarComponents/CapacityComponent.vue'
import StoreMixin from './storeMixin.js'
import QuoteRoutes from './route-types'

export default {
  name: 'QuoteBooking',
  components: { CapacityComponent },
  mixins: [StoreMixin],
  props: {
    value: null,
    sideSubMenu: null
  },
  data() {
    return {
      updateData: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    routeTypes() {
      return QuoteRoutes
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        console.log(newVal.readOnly)
        this.updateData = true
      },
      deep: true
    }
  },
  methods: {
    handleQuoteRedirect(quote) {
      this.setQuoteReturnRoute(this.$route)
      this.editStoreQuote(quote.quoteId)
      // this.$router.push({ name: this.routeTypes.QuoteBooking.name, params: { quoteId: quote.quoteId }, query: this.$route.query })
    },
    getTotal(value) {
      return value.rrTotal + value.repairTotal + value.paintTotal + value.mechTotal + value.partTotal + value.sublTotal + value.miscTotal
    }
  }
}
</script>

<style lang="scss">
.quote-booking-side-panel {
  width: 20%;
  height: 100%;
  background-color: #343a40;
}

.label-text {
  width: 80%;
  padding-bottom: 0.4em;
  border-bottom: 2px solid #336190 !important;
}
.value-text {
  margin-top: 0.5em;
  margin-right: 0.1em;
  width: 80%;
  padding-bottom: 0.4em;
  border-bottom: 1px solid rgb(255, 255, 255, 0.2);
  .text {
    font-size: 0.875em;
  }
  // display grid
  display: grid;
  grid-template-columns: 60% 40%;
}

.booking-field-label {
  color: #ffffff;
  margin: 0.5em;
}
</style>